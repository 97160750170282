import request from '@/utils/request'


/**
 * 获取咨询详情
 * @param newsId 咨询ID
 */
export const findNews = (newsId) => {
  return request('/front/news/news/detail?url=&appId=10002', 'get', {newsId})
}

/**
 * 获取资讯类别
 */

export const findNewsCategory = () => {
  return request('/front/news/category/index?url=&appId=10002', 'get')
}

/**
 * 获取资讯
 * @param {Object} params - 可参考接口文档
 */
export const findNewsByCategoryId = (params) => {
  params.appId = 10002
  return request('/front/news/news/lists?url=&appId=10002', 'post', params)
}
