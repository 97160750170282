<template>
  <div class="home-new">
    <LeftTitle title="新闻资讯" :hava-slot="true" sub-title="公司最新动态">
      <div ref="target" style="position: relative;height: 360px;">
        <!-- 面板内容 -->
        <Transition name="fade">
          <ul class="home-news-list">
            <li v-for="(news,index) in newsList.value">
              <RouterLink :to="`/news/detail/${news.newsId}`">
                <img :src="news.newsImage" :alt="news.newsName">
                <p class="name ellipsis">{{ news.newsName }}</p>
                <p class="attr ellipsis-2" style="text-align: left">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                    news.newsDesc
                  }}</p>
                <p class="time ellipsis-3">{{ news.createTime }}</p>
                <!-- <p class="price">&yen;</p> -->
              </RouterLink>
            </li>
          </ul>
        </Transition>
      </div>
    </LeftTitle>
  </div>
</template>
<script>
import {onMounted, reactive} from "vue";
import {findNewsByCategoryId} from "@/api/news";
import LeftTitle from "@/components/library/base-left-title.vue";

export default {
  name: 'HomeNew',
  components: {LeftTitle},
  setup() {
    const newsList = reactive([])
    const reqParams = reactive({
      pageIndex: 1,
      pageSize: 3,
    })
    // watch(() => route.params.id, () => {
    //   findNewsByCategoryId(reqParams).then(data => {
    //     newsList.value = data.data.records
    //   })
    // }, {immediate: true})
    onMounted(() => {
      findNewsByCategoryId(reqParams).then(data => {
        newsList.value = data.data.records
      })
    })
    return {newsList}
  }
}
</script>
<style scoped lang="less">
.home-new {
  height: 60vh;

  .home-news-list {
    display: flex;
    justify-content: space-between;
    height: 40vh;

    li {
      width: 32%;
      min-height: 15vh;
      background: #fff;
      justify-content: center;
      align-items: center;
      .hoverShadow();

      img {
        width: 100%;
        height: 27vh;
      }

      p {
        line-height: 2vh;
        font-size: 2vh;
        text-align: center;
        padding-top: 1vh;

        &.attr {
          color: #999;
          font-size: 1.5vh;
          height: 9vh;

          span {
            margin-right: 5px;
            padding-left: 5px;
          }
        }

        &.time {
          color: #999;
          font-size: 2vh;
          text-align: right;
        }

        .price {
          color: @priceColor;
        }
      }
    }
  }

  :after {
    content: '';
    display: block;
    clear: both;
  }
}
</style>
